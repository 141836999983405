<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{ $t("filter") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc("buttons.button_type", 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="buttonFilter"
            :options="buttonOptions"
            class="w-100"
            multiple
            v-model="filterItems"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:buttonFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    buttonOptions: {
      type: Array,
      default: null,
    },
    buttonFilter: {
      type: Array | Number | String,
      default: null,
    },
  },
  data() {
    return { filterItems: [], datafilter: [] };
  },
  // start breadcrumbs
  methods: {
    sendItemFilter() {
      this.datafilter = [];
      this.datafilter.push(this.filterItems);

      this.$emit("itemFilter", this.datafilter);

    },
    returnItemFilter() {
      this.getItemsFilter.forEach((element) => {
        if (this.$route.path == element.to && element.itemFilter == []) {
          this.filterItems = element.itemFilter[0];
        }
      });
    },
  },
  computed: {
    getItemsFilter() {
      return store.getters["breadcrumbNav/getItemsNav"];
    },
  },
  watch: {
    filterItems: function () {
      this.sendItemFilter();
    },
  },
  created() {
    this.returnItemFilter();
    if (this.filterItems == null) {
      this.$emit("update:buttonFilter", this.filterItems);
    }
  },
  // end breadcrumbs
};
</script>